<template>
  <div>
    <search-block title="отходов производства">
      <el-row :gutter="10">
        <el-col :span="22">
          <el-input @input="searchWaste" v-model="search"></el-input>
        </el-col>
        <el-col :span="2">
          <el-button @click="showAddPage" type="success"> Добавить</el-button>
        </el-col>
      </el-row>
    </search-block>

    <el-table stripe :data="wasteProduction.content">
      <el-table-column label="Отход">
        <template slot-scope="item">
          <div :style="(new Date(item.row.dateFrom) > new Date() && item.row.dateFrom!=null)
              ? { color: '#e2eb34'} : 
              new Date(item.row.dateTo) < new Date() && item.row.dateTo!=null && item.row.datEd
              ? { color: '#eb6b34'} : {}">
            {{item.row.organizationWasteId + ' / ' + item.row.ss01WasteDictionaryDTO.wasteCode }} -
            {{ item.row.ss01WasteDictionaryDTO.wasteName }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Начало/конец" width="150">
        <template slot-scope="item">
          <!-- eslint-disable -->
          <div>
            
            {{(item.row.dateFrom != null ? ("С " + (new Date(item.row.dateFrom).getDate()<10 ? "0" : "") + new Date(item.row.dateFrom).getDate() + "." + (new Date(item.row.dateFrom).getMonth()<9 ? "0" : "") + (new Date(item.row.dateFrom).getMonth()+1)+ "." + new Date(item.row.dateFrom).getFullYear()) : "") + (item.row.dateTo != null ? (" По " + (new Date(item.row.dateTo).getDate()<10 ? "0" : "") + new Date(item.row.dateTo).getDate() + "." + (new Date(item.row.dateTo).getMonth()<9 ? "0" : "") + (new Date(item.row.dateTo).getMonth()+1)+ "." + new Date(item.row.dateTo).getFullYear()) : "")}}
          </div>
          <!-- eslint-enable -->
        </template>
      </el-table-column>
      <el-table-column
        label="Организация"
        prop="organizationInfo.name"
      ></el-table-column>
      <el-table-column width="150"  label="" >
        <template slot-scope="row">
          <el-row :gutter="10">
            <el-col :span="24">
              <el-button
                size="mini"
                style="width: 100%"
                @click="editWaste(row.row)"
                type="warning"
                >Редактировать</el-button
              >
              <el-button
                size="mini"
                style="width: 100%; margin-left: 0; margin-top: 5px"
                type="danger"
                @click="deleteWaste(row.row)"
                >Удалить</el-button
              >
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      style="text-align: center"
      layout="total, sizes, prev, pager, next"
      background
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="wasteProduction.size"
      :current-page.sync="wasteProduction.number + 1"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :total="wasteProduction.totalElements"
    >
    </el-pagination>
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import SearchBlock from "@/components/searchBlock";
import notification from "@/mixins/notification";

export default {
  name: "wasteproductionTable",
  components: { SearchBlock },
  mixins: [notification],
  data() {
    return {
      search: null,
      selectedItem: null,
    };
  },

  computed: {
    ...mapGetters({
      wasteProduction: "GETLISTWASTEPRODUCTION",
      showModal: "GETSHOWMODAL",
    }),
  },

  methods: {
    handleSizeChange(val) {
      this.getListAllOrganizationWastes({ page: 0, size: val });
    },

    handleCurrentChange(val) {
      this.getListAllOrganizationWastes({
        page: val - 1,
        size: this.wasteProduction.size,
      });
    },

    searchWaste(query) {
      console.log(query);
      this.$store.dispatch("searchOrganizationWastes", {
        query: query,
        size: 20,
        page: 0,
      });
    },

    showAddPage() {
      console.log({ size: this.wasteProduction.size,page:this.wasteProduction.number });
      this.$router.push({path:"add",
      query: { size: this.wasteProduction.size,page:this.wasteProduction.number } });
    },

    async editWaste(item) {
      await this.$store.dispatch(
        "getOrganizationWaste",
        item.organizationWasteId
      );
      await this.$router.push({path:"edit/" + item.organizationWasteId,
      query: { size: this.wasteProduction.size,page:this.wasteProduction.number } });
    },

    deleteWaste(item) {
      console.log(item);
      this.confirm(
        "Удалить отход",
        "Вы действительно хотите удалить отход?",
        "warning",
        "Удалить",
        "Отмена"
      ).then(() => {
        this.$store
          .dispatch("deleteOrganizationWaste", item.organizationWasteId)
          .then(() => {
            this.notification("Успешно", "Отход удален", "success");
            this.getListAllOrganizationWastes({
              page: this.wasteProduction.number,
              size: this.wasteProduction.size,
            });
          });
      });
    },

    getListAllOrganizationWastes(data) {
      this.$store.dispatch("getListAllOrganizationWastes", data);
    },
  },

  mounted() {
    this.getListAllOrganizationWastes({ page: this.$route.query.page!=undefined?this.$route.query.page:0, size: this.$route.query.size!=undefined?this.$route.query.size:20 });
  },
};
</script>

<style scoped></style>
